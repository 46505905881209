export default {
    title: '<Title of KPI>',
    location: '/<kpi-area>/<kpi-name>',
    tabs: [
        {
            name: 'By Country',
            link: `/<kpi-area>/<kpi-name>`
        },
        {
            name: 'Trend',
            link: `/<kpi-area>/<kpi-name>/:market`
        }
    ]
}
